import {
    NavLink,
    useLocation
} from 'react-router-dom';

import { useState, useEffect } from 'react';

import { ReactSVG } from 'react-svg';

import sidebarNavItems from '../../constants/sidebarNavItems.json';

import LogoutButton from './LogoutButton';

import account from '../../images/icons/menu/account.svg';
import home from '../../images/icons/menu/home.svg';
import screen from '../../images/icons/menu/screen.svg';
import foresight from '../../images/icons/menu/foresight.svg';
import insight from '../../images/icons/menu/insight.svg';
import mobile from '../../images/icons/menu/mobile.svg';
import mobilityscore from '../../images/icons/menu/mobilityscore.svg';
import enhancedContent from '../../images/icons/menu/enhanced-content.svg';
import manageScreens from '../../images/icons/menu/manage-screens.svg';
import messaging from '../../images/icons/menu/messaging.svg';
import contentManagement from '../../images/icons/menu/content-management.svg';
import createNew from '../../images/icons/menu/create-new.svg';
import reportAnalytics from '../../images/icons/menu/report-analytics.svg';
import gear from '../../images/icons/menu/gear.svg';
import cash from '../../images/icons/menu/cash.svg';
import expanded from '../../images/icons/menu/expanded.svg';
import collapsed from '../../images/icons/menu/collapsed.svg';
import collapsedDisabled from '../../images/icons/menu/collapsed-disabled.svg';
import resources from '../../images/icons/menu/resources.svg';
import locations from '../../images/icons/menu/locations.svg';
import subscriptions from '../../images/icons/menu/subscriptions.svg';
import support from '../../images/icons/menu/support.svg';
import communicationTools from '../../images/icons/menu/communicationTools.svg';
import headset from '../../images/icons/menu/headset.svg';
import mapper from '../../images/icons/menu/mapper.svg';
import playlists from '../../images/icons/menu/playlists.svg';
import supportCenter from '../../images/icons/menu/support-center.svg';
import adminv1 from '../../images/icons/menu/adminv1.svg';
import customers from '../../images/icons/menu/customers.svg';
import users from '../../images/icons/menu/users.svg';
import tsIcon from '../../images/icons/ts-icon.svg';

const icons = {
    account,
    home,
    screen,
    foresight,
    insight,
    mobile,
    enhancedContent,
    mobilityscore,
    resources,
    subscriptions,
    support,
    locations,
    communicationTools,
    manageScreens,
    messaging,
    contentManagement,
    createNew,
    reportAnalytics,
    gear,
    cash,
    headset,
    mapper,
    playlists,
    supportCenter,
    adminv1,
    customers,
    users,
    tsIcon
}

const SidebarMenu = ({
    lang,
    setModal,
    enabledNavs,
    adminMenu
}) => {

    const [currentPath, setPath] = useState(window.location.pathname)
    const [expandedMenu, setExpandedMenu] = useState([]);
    //For detecting location changes
    const location = useLocation();

    // There are some icons that shouldn't have  an svg "fill" because they turn into gray blobs.
    const noFillIcons = ['gear', 'mobilityscore', 'playlists', 'cash', 'createNew', 'reportAnalytics', 'adminv1'];

    const onClick = (path) => {
        setPath(path)        
    }

    //Detect change that happened outside of sidebar.js
    //e.g. going from report analytics to create new report
    useEffect(() => {
        setPath(window.location.pathname)
    }, [location])

    const handleExpand = (menu) => {
        if (expandedMenu.includes(menu)) {
            const tempExpandedMenu = [...expandedMenu];
            const index = tempExpandedMenu.indexOf(menu);
            tempExpandedMenu.splice(index, 1);
            setExpandedMenu(tempExpandedMenu)
        }
        else {
            const tempExpandedMenu = [...expandedMenu, menu];
            setExpandedMenu(tempExpandedMenu)
        }
    }

    const pathIsInSubNav = (subNav) => {
        return subNav?.some((nav) => {
            return currentPath === nav.path;
        });
    }

    const injectSvg = (icon, currentPath, nav) => {
        return <ReactSVG src={icon} className={currentPath === nav.path && !nav.disabled ? "svg-filter" : ""} beforeInjection={(svg) => modifyIconColor(svg, nav)} />
    }

    const modifyIconColor = (svg, nav) => {
        if (nav.disabled) {
            for (var i = 0; i < svg.children.length; i++) {
                svg.children[i].setAttribute('stroke', 'gray');
                if (!noFillIcons.includes(nav.icon)) {
                    svg.children[i].setAttribute('fill', 'gray');
                }
            }
        }
    }

    const getLinkClassNames = (nav) => {
        return `pure-u-1-1 pure-u-md-1-1 menu-option nav-container ${nav.disabled ? "disabled" : ""}`;
    }

    const getIconAndTitle = (icon, currentPath, nav, isSubItem) => {
        var iconClasses = isSubItem ? 'pure-u-1-5 pure-u-md-1-5' : 'pure-u-1-6 pure-u-md-1-6';
        var titleClasses = isSubItem ?
            'pure-u-4-5 pure-u-md-4-5' :
            (nav.subNavItems?.length > 0 ? 'pure-u-2-3 pure-u-md-2-3' : 'pure-u-5-6 pure-u-md-5-6');

        var expandIcon = nav.subNavItems?.length > 0 ?
            <div className="pure-u-1-6 pure-u-md-1-6 menu-option-expand-icon">
            <img className="expand-icon" src={
                    nav.disabled ?
                    collapsedDisabled
                    : expandedMenu.includes(nav.title) || pathIsInSubNav(nav.subNavItems) ?
                        expanded :
                        collapsed
                }
            />
            </div> :
            null;

        return <>
             <div className={`icon ${iconClasses}`}>
                { injectSvg(icon, currentPath, nav) }
            </div>
            <div className={titleClasses}>{nav.title}</div>
            { expandIcon }
        </>
    }

    var menu = [];
    sidebarNavItems.forEach((nav) => {
        // Skip this menu item if it's for admin only and the end user is not an Actionfigure employee
        if (nav.access === 'admin' && !adminMenu) {
            return;
        }

        const icon = icons[nav.icon] || null;

        // If a nav item is enabled, determine if end user can see it
        if (nav.disabled === undefined || nav.disabled === false) {
            var accessAllowed = nav.access === 'public' || // Anybody can see it
                (adminMenu) || // Admin users can see everything
                (nav.access === 'permission' && enabledNavs.includes(nav.key)); // End user's account has permission
            // Negate the enabled-ness
            nav.disabled = !(accessAllowed);
        }

        if (nav.endGrouping) {
            menu.push(<hr key={nav.key}/>);
            return;
        }

        menu.push(
            <ul key={nav.title}>
                <li
                    className={ currentPath === nav.path ? "title selected" : "title" }
                    onClick={ nav.path ? () => onClick(nav.path) : null }
                >
                    {
                        nav.path ?
                        <NavLink to={nav.disabled ? '#' : nav.path} className={ getLinkClassNames(nav) }>
                            { getIconAndTitle(icon, currentPath, nav, false) }
                        </NavLink> :
                        nav.externalLink ?
                            <a href={nav.disabled ? '#' : nav.externalLink} className={ getLinkClassNames(nav) } target="_blank">
                                { getIconAndTitle(icon, currentPath, nav, false) }
                            </a> :
                            <div className={ getLinkClassNames(nav) } onClick={nav.disabled ? null : () => handleExpand(nav.title)}>
                                { getIconAndTitle(icon, currentPath, nav, false) }
                            </div>
                    }
                    {
                        nav?.subNavItems && (expandedMenu.includes(nav.title) || pathIsInSubNav(nav.subNavItems)) ?
                            <div className="sub-nav-container">
                            {
                                nav?.subNavItems.map(subItem => { 
                                    return subItem.path && !subItem.disabled ?
                                        <NavLink to={subItem.path} className={ getLinkClassNames(subItem) + `${currentPath === subItem.path ? "selected" : ""} `} key={subItem.title} onClick={() => subItem.disabled ? null : onClick(subItem.path)}>
                                            { getIconAndTitle(icons[subItem.icon], currentPath, subItem, true) }
                                        </NavLink> :
                                        subItem.externalLink ?
                                            <a href={subItem.disabled ? '#' : subItem.externalLink} className={ getLinkClassNames(subItem) } target="_blank" key={subItem.title}>
                                                { getIconAndTitle(icons[subItem.icon], currentPath, subItem, true) }
                                            </a> :
                                            <div className={`pure-u-1-1 pure-u-md-1-1 menu-option disabled`} key={subItem.title}>
                                                { getIconAndTitle(icons[subItem.icon], currentPath, subItem, true) }
                                            </div>
                                })
                            }
                            </div>
                            : <></>
                    }
                </li>
                {nav.items && nav.items.map((item) => {

                    let itemSelected = currentPath === item.path;

                    return (
                        <li key={item.path} className={itemSelected ? "item selected" : "item"}><NavLink onClick={() => onClick(item.path)}  to={item.path}>{item.title}</NavLink></li>
                    )
                })}
            </ul>
        );
    });

    return (
        <div className="SidebarMenu">

            <nav className="nav-list">
                {menu}
                <ul key="logout-button"><li className="title"><LogoutButton setModal={setModal} lang={lang} /></li></ul>
            </nav>

        </div>
    )
}


export default SidebarMenu;
