import React, {
    useState
} from 'react';
import Select from 'react-select';
import Switch from "react-switch";
import dropdownOptions from './dropdownOptions.json';
import constants from './constants.json';
import { HexColorInput } from "react-colorful";
import { PopoverPicker } from '../ColorPicker/PopoverPicker';

const TransportationContent = ({
    configs,
    handleColorChange,
    handleConfigChanges,
    handleMultiConfigChange,
    handleSliderChange,
    access
}) => {

    const [searchEnabled, setSearchEnabled] = useState(configs?.menu !== null);
    const [modeEnabled, setModeEnabled] = useState(configs?.barPosition !== null);

    const handleShowToggle = (cb, prop, value, checked) => {
        cb(checked);
        handleConfigChanges([prop], checked ? [value] : [null])
    }

    const allowedModes = constants.ALLOWED_MODES.map(mode => {
        let isChecked = configs?.allowedModes ? configs?.allowedModes.includes(mode.value) : false;

        return <div className="mode-container" key={mode.value}>
            <input type="checkbox" checked={isChecked} onChange={() => handleMultiConfigChange('allowedModes', mode.value)} />
            <p>{mode.label}</p>
        </div>
    })

    const changeMainBgColor = (color) => {
        handleColorChange('mainBg', color.substring(1));
    }

    const changeMainTextColor = (color) => {
        handleColorChange('mainText', color.substring(1));
    }

    const changeAccentColor = (color) => {
        handleColorChange('accentColor', color.substring(1));
    }

    const changeCardBgColor = (color) => {
        handleColorChange('cardBg', color.substring(1));
    }

    const changeCardTextColor = (color) => {
        handleColorChange('cardText', color.substring(1));
    }

    const changeBorderColor = (color) => {
        handleColorChange('borderColor', color.substring(1));
    }

    const changeMenuBgColor = (color) => {
        handleColorChange('menuBg', color.substring(1));
    }

    const changeMenuTextColor = (color) => {
        handleColorChange('menuText', color.substring(1));
    }

    const changeNavBgColor = (color) => {
        handleColorChange('navBg', color.substring(1));
    }

    const changeNavTextColor = (color) => {
        handleColorChange('navText', color.substring(1));
    }

    //Special case for interactive section
    const handleInteractiveSwitch = (checked) => {
        //When interactive is turned off, these params need to be turned off as well
        if (!checked) {
            handleConfigChanges(['favorites', 'maps', 'externalLinks', 'interactive'], [false, false, false, checked]);
        }
        else {
            handleConfigChanges(['interactive'], [checked]);
        }
    }

    return (
        <div className="TransportationContent">
            <div className="transportation-box">
                <h3>Transportation content</h3>
                <div className="left-col pure-u-1-2">                    
                    <h4>Modes of transportation</h4>
                    <p>Choose the Transportation types (“modes”) that you would like to show on your Screen. These are general categories so for instance “Rail” may include anything that runs on a rail, such as trains and subways.</p>
                    <div className="allowed-modes">
                        {allowedModes}
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Search area:</p>
                        <div className="search-radius-container">
                            <input type="range" min="0.1722" max="1.8278" step="0.4139" list="tickmarks" value={configs.radius ? configs.radius : "1"} onChange={(e) => handleSliderChange('radius', e.target.value)}></input>
                            <datalist id="tickmarks">
                                {/* The option values are the label divided by 1208 (m), up to 4 decimal places.
                                The search radius here eventually becomes the radius _multiplier_ in MOB.
                                So if radius=1, that means use the default radius (1208 m). If radius=2, that means
                                double the radius, not search within 2 km. */}
                                <option value="0.1722" label="0.2 km"></option>
                                <option value="0.5861" label="0.7 km"></option>
                                <option value="1" label="1.2 km"></option>
                                <option value="1.4139" label="1.7 km"></option>
                                <option value="1.8278" label="2.2 km"></option>
                            </datalist>
                        </div>
                    </div>
                </div>
                <div className="pure-u-1-2">

                </div>   
            </div>
            <div className="color-box">
                <h3>Colors and layout</h3>
                <div className="left-col pure-u-1-2">
                    <div className="input-container">
                        <div className="label pure-u-1-6">Background:</div>
                        <HexColorInput className="input" color={configs?.mainBg || '#FFFFFF'} onChange={changeMainBgColor} prefixed={true} />
                        <PopoverPicker color={`#${configs?.mainBg}` || '#FFFFFF'} onChange={changeMainBgColor} prefixed={true} />
                    </div>
                    <div className="input-container">
                        <div className="label pure-u-1-6">Text:</div>
                        <HexColorInput className="input" color={configs?.mainText || '#000000'} onChange={changeMainTextColor} prefixed={true} />
                        <PopoverPicker color={`#${configs?.mainText}` || '#000000'} onChange={changeMainTextColor} />
                    </div>
                    <div className="input-container">
                        <div className="label pure-u-1-6">Accents:</div>
                        <HexColorInput className="input" color={configs?.accentColor || '#2386EE'} onChange={changeAccentColor} prefixed={true} />
                        <PopoverPicker color={`#${configs?.accentColor}` || '#2386EE'} onChange={changeAccentColor} />
                    </div>
                </div>
                <div className="right-col pure-u-1-2">
                    <div className="input-container">
                        <div className="label pure-u-1-6">Icon style:</div>
                        <Select  
                            onChange={(e) => handleConfigChanges(['ui'], [e.value])}
                            options={dropdownOptions.UI}
                            value={dropdownOptions.UI.find(option => option.value === configs?.ui)}
                            className={"input dropdown-list"}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                    {
                        access === 'screen'
                        ? <div className="input-container pure-g">
                            <div className="label pure-u-1-6">Resolution:</div>
                            <div className="slider-container pure-u-5-6">
                                <input className="zoom-range" type="range" min="50" max="300" step="1" list="tickmarks" value={configs.zoom} onChange={(e) => handleSliderChange('zoom', e.target.value)}></input>
                                <datalist id="tickmarks">
                                    <option value="50" label="Low"></option>
                                    <option value="300" label="High"></option>
                                </datalist>
                            </div>
                        </div>
                        : <></>
                    }                    
                </div>
            </div>
            <div className="cards-box">
                <h3>Transportation information "cards"</h3>
                <div className="left-col pure-u-1-2">
                    <div className="input-container">
                        <div className="label pure-u-1-6">Background:</div>
                        <HexColorInput className="input" color={configs?.cardBg || 'FFFFFF'} onChange={changeCardBgColor} prefixed={true} />
                        <PopoverPicker color={`#${configs?.cardBg}` || 'FFFFFF'} onChange={changeCardBgColor} />
                    </div>
                    <div className="input-container">
                        <div className="label pure-u-1-6">Text:</div>
                        <HexColorInput className="input" color={configs?.cardText || '000000'} onChange={changeCardTextColor} prefixed={true} />
                        <PopoverPicker color={`#${configs?.cardText}` || '000000'} onChange={changeCardTextColor} />
                    </div>
                    <div className="input-container">
                        <div className="label pure-u-1-6">Border:</div>
                        <HexColorInput className="input" color={configs?.borderColor || '#FFFFFF'} onChange={changeBorderColor} prefixed={true} />
                        <PopoverPicker color={`#${configs?.borderColor}` || '#FFFFFF'} onChange={changeBorderColor} />

                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Time format:</p>
                        <Select  
                            onChange={(e) => handleConfigChanges(['timeDisplay'], [e.value])}
                            options={dropdownOptions.TIME_FORMAT}
                            value={dropdownOptions.TIME_FORMAT.find(option => option.value === configs?.timeDisplay)}
                            className={"input dropdown-list"}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Sort order:</p>
                        <Select  
                            onChange={(e) => handleConfigChanges(['sortOrder'], [e.value])}
                            options={dropdownOptions.SORT_ORDER}
                            value={dropdownOptions.SORT_ORDER.find(option => option.value === configs?.sortOrder)}
                            className={"input dropdown-list"}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                </div>
                <div className="right-col pure-u-1-2">
                    <div className="input-container">
                        <p className="label pure-u-1-6">Card header:</p>    
                        <Switch className="switch" checked={configs?.cardHeader === undefined ? true : configs?.cardHeader } onChange={(checked) => handleConfigChanges(['cardHeader'], [checked])} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Vehicle color:</p>    
                        <Switch className="switch" checked={configs?.vehicleColor === undefined ? true : configs?.vehicleColor } onChange={(checked) => handleConfigChanges(['vehicleColor'], [checked])} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Soft corners:</p>    
                        <Switch className="switch" checked={configs?.borderRounding === undefined ? true : configs?.borderRounding } onChange={(checked) => handleConfigChanges(['borderRounding'], [checked])} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Shadow:</p>    
                        <Switch className="switch" checked={configs?.cardShadow === undefined ? true : configs?.cardShadow } onChange={(checked) => handleConfigChanges(['cardShadow'], [checked])} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Walk time:</p>    
                        <Switch className="switch" checked={configs?.cardWalkTime === undefined ? true : configs?.cardWalkTime } onChange={(checked) => handleConfigChanges(['cardWalkTime'], [checked])} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Group rows:</p>    
                        <Switch className="switch" checked={configs?.groupTransitRows === undefined ? true : configs?.groupTransitRows } onChange={(checked) => handleConfigChanges(['groupTransitRows'], [checked])} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
                    <div className="input-container" style={{marginBottom: '1em'}}>
                        <p className="label pure-u-1-6">Expandable:</p>    
                        <Switch className="switch" checked={configs?.interactive === undefined ? true : configs?.interactive } onChange={(checked) => handleConfigChanges(['interactive'], [checked])} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
                </div>
            </div>
            {
                access === 'mobile'
                ? <div className="search-box">
                    <h3>Search and weather bar</h3>
                    <div className="left-col pure-u-1-2">
                        <div className="input-container">
                            <p className="label pure-u-1-6">Show:</p>
                            <Switch className="switch" checked={configs?.menu !== null} onChange={(checked) => handleShowToggle(setSearchEnabled, 'menu', 'anywhere', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6">Type:</p>
                            <Select  
                                onChange={(e) => handleConfigChanges(['menu'], [e.value])}
                                options={dropdownOptions.TYPE}
                                value={dropdownOptions.TYPE.find(option => option.value === configs?.menu)}
                                className={`input dropdown-list ${searchEnabled ? "" : "disabled"}`}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isDisabled={!searchEnabled}
                            />
                        </div>
                        <div className="input-container">
                            <div className="label pure-u-1-6">Background:</div>
                            <HexColorInput className={`input ${searchEnabled ? "" : "disabled"}`} color={configs?.menuBg || '#081754'} onChange={changeMenuBgColor} disabled={!searchEnabled} prefixed={true} />
                            <PopoverPicker color={`#${configs?.menuBg}` || '#081754'} onChange={changeMenuBgColor} />
                        </div>
                        <div className="input-container">
                            <div className="label pure-u-1-6">Text:</div>
                            <HexColorInput className={`input ${searchEnabled ? "" : "disabled"}`} color={configs?.menuText || '#FFFFFF'} onChange={changeMenuTextColor} disabled={!searchEnabled} prefixed={true} />
                            <PopoverPicker color={`#${configs?.menuText}` || '#FFFFFF'} onChange={changeMenuTextColor} />
                        </div>
                    </div>
                    <div className="right-col pure-u-1-2"></div>
                </div>
                : <></>
            }
            {
                access === 'mobile'
                ? <div className="mode-box">
                    <h3>Mode selection bar</h3>
                    <div className="left-col pure-u-1-2">
                        <div className="input-container">
                            <p className="label pure-u-1-6">Show:</p>
                            <Switch className="switch" checked={configs?.barPosition !== null} onChange={(checked) => handleShowToggle(setModeEnabled, 'barPosition', 'bottom', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>
                        </div>
                        <div className="input-container">
                            <div className="label pure-u-1-6">Background:</div>
                            <HexColorInput className={`input ${modeEnabled ? "" : "disabled"}`} color={configs?.navBg || '#081754'} onChange={changeNavBgColor} disabled={!searchEnabled} prefixed={true} />
                            <PopoverPicker color={`#${configs?.navBg}` || '#081754'} onChange={changeNavBgColor} />
                        </div>
                        <div className="input-container">
                            <div className="label pure-u-1-6">Text:</div>
                            <HexColorInput className={`input ${modeEnabled ? "" : "disabled"}`} color={configs?.navText || '#FFFFFF'} onChange={changeNavTextColor} disabled={!searchEnabled} prefixed={true} />
                            <PopoverPicker color={`#${configs?.navText}` || '#FFFFFF'} onChange={changeNavTextColor} />
                        </div>
                        <div className="input-container">
                            <div className="label pure-u-1-6">Border:</div>
                            <HexColorInput className={`input ${modeEnabled ? "" : "disabled"}`} color={configs?.borderColor || '#FFFFFF'} onChange={changeBorderColor} disabled={!searchEnabled} prefixed={true} />
                            <PopoverPicker color={`#${configs?.borderColor}` || '#FFFFFF'} onChange={changeBorderColor} />
                        </div>
                    </div>
                    <div className="right-col pure-u-1-2">
                        <div className="input-container">
                            <p className="label pure-u-1-6">Opening mode:</p>
                            <div className="radio-container">
                                <input type="radio" className="radio-input" checked={configs?.openTo === 'all'} onChange={() => handleConfigChanges(['openTo'], ['all'])} />
                                <p className="text">Only show “All” tab</p>
                            </div>
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6" style={{visibility: "hidden"}}>Opening mode:</p>
                            <div className="radio-container">
                                <input type="radio" className="radio-input" checked={configs?.openTo !== 'all'} readOnly />
                                <Select  
                                    onChange={(e) => handleConfigChanges(['openTo'], [e.value])}
                                    options={dropdownOptions.OPEN_TO}
                                    value={dropdownOptions.OPEN_TO.find(option => option.value === configs?.openTo)}
                                    className={`radio-select dropdown-list ${modeEnabled ? "" : "disabled"}`}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isDisabled={!modeEnabled}
                                />
                            </div>
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6">Location:</p>
                            <Select  
                                onChange={(e) => handleConfigChanges(['barPosition'], [e.value])}
                                options={dropdownOptions.BAR_POSITION}
                                value={dropdownOptions.BAR_POSITION.find(option => option.value === configs?.barPosition)}
                                defaultValue={{
                                    "label": "Bottom of Screen",
                                    "value": "bottom"
                                }}
                                className={`input dropdown-list ${modeEnabled ? "" : "disabled"}`}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isDisabled={!modeEnabled}
                            />
                        </div>
                    </div>
                </div>
                : <></>
            }
            {
                access === 'mobile'
                ? <div className="advanced-box">
                    <h3>Interactivity</h3>
                    <div className="pure-g">
                        <div className="advanced-mobile-description left-col pure-u-1-3">
                            <p>These features allow users to indicate their favorite stops/stations, tap into a “card” to see a map with enhanced arrivals information, and even turn on/off the ability to open other transportation apps (Uber, Lyft, etc) at the press of a button.</p>
                        </div>
                        <div className="interactivity-switches pure-u-2-3">
                            <div className="input-container">
                                <p className="label pure-u-1-6">Enable Interactivity:</p>
                                <Switch className="switch" checked={configs?.interactive === undefined ? true : configs?.interactive } onChange={(checked) => handleInteractiveSwitch(checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>
                            </div>
                            <div className="input-container">
                                <p className="label pure-u-1-6">Favorites:</p>
                                <Switch className="switch" checked={configs?.favorites === undefined ? true : configs?.favorites } onChange={(checked) => handleConfigChanges(['favorites'], [checked])} disabled={!configs?.interactive} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>
                            </div>
                            <div className="input-container">
                                <p className="label pure-u-1-6">Maps:</p>
                                <Switch className="switch" checked={configs?.maps === undefined ? true : configs?.maps } onChange={(checked) => handleConfigChanges(['maps'], [checked])} disabled={!configs?.interactive} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>
                            </div>
                            <div className="input-container">
                                <p className="label pure-u-1-6">Other apps:</p>
                                <Switch className="switch" checked={configs?.externalLinks === undefined ? true : configs?.externalLinks } onChange={(checked) => handleConfigChanges(['externalLinks'], [checked])} disabled={!configs?.interactive} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>
                            </div>
                        </div>
                    </div>
                </div>
                : <></>
            }
            {
                access === 'screen'
                ? <div className="scroll-box">
                    <h3>Scrolling content</h3>
                    <div className="left-col pure-u-1-3">
                        <p>If there are more transportation options than can fit on your display at one time, you can enable Scrolling. Scrolling will vertically “roll” each column of transportation content.</p>
                    </div>
                    <div className="right-col pure-u-1-2">
                        <div className="input-container">
                            <p className="label pure-u-1-6">Scroll content:</p>    
                            <Switch className="switch" checked={configs?.scroll === undefined ? true : configs?.scroll } onChange={(checked) => handleConfigChanges(['scroll'], [checked])} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6">Scroll speed:</p>    
                            <Select  
                                onChange={(e) => handleConfigChanges(['scrollSpeed'], [e.value])}
                                options={dropdownOptions.SCROLL_SPEED}
                                value={dropdownOptions.SCROLL_SPEED.find(option => option.value === configs?.scrollSpeed)}
                                defaultValue={{
                                    "label": "Slow",
                                    "value": "slow"
                                }}
                                className={`input disabled dropdown-list`}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                </div>
                : <></>
            }
        </div>
    );
}

export default TransportationContent;
