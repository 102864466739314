import { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import Switch from "react-switch";
import { DndContext, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { getPlaylistData, createOrUpdatePlaylistSlide, removePlaylist, createPlaylist, setLoopDuration } from './controller';
import SlideModal from './SlideModal';
import Droppable from './Droppable';
import Draggable from './Draggable';
import { Oval } from 'react-loader-spinner';
import { getCustomerData } from '../../utils';
import playlistOptions from './playlistOptions.json';

import addImage from '../../images/icons/add-image.svg';
import arrowLeft from '../../images/icons/left-angle.svg';
import arrowLeftDisabled from '../../images/icons/left-angle-disabled.svg';
import arrowRight from '../../images/icons/right-angle.svg';
import arrowRightDisabled from '../../images/icons/right-angle-disabled.svg';
import transitIcon from '../../images/icons/screens/transit.svg';
import dragIcon from '../../images/contentManagement/drag-icon.svg'
import deleteIcon from '../../images/icons/analytics/trash.svg';
import logo from '../../images/logos/af-logo.svg'
import plusIcon from '../../images/icons/plus-sign.svg';
import linkIcon from '../../images/icons/link.svg';

const DEFAULT_PLAYLIST_SLIDES = Array.from(Array(5), (_, index) => {
	return {
		sortOrder: index + 1,
		durationMs: 10000
	}
})
const PAGE_SIZE = 3;
const PLAYLIST_SLIDE_NUMBER = 5;
const DURATION_OPTIONS = [
	{label: '3 sec', value: 3000},
	{label: '5 sec', value: 5000},
	{label: '10 sec (default)', value: 10000},
	{label: '15 sec', value: 15000},
	{label: '30 sec', value: 30000},
	{label: '60 sec', value: 60000},
	{label: 'Permanent', value: null, disabled:true},
];

const ContentManagementPage = ({
	userData
}) => {

	const [slides, setSlides] = useState([]);
	const [playlists, setPlaylists] = useState([]);
	const [playlistSlides, setPlaylistSlides] = useState(DEFAULT_PLAYLIST_SLIDES);
	const [initialPlaylistSlides, setInitialPlaylistSlides] = useState(DEFAULT_PLAYLIST_SLIDES);
	const [selectedPlaylist, setSelectedPlaylist] = useState({});
	const [page, setPage] = useState(0);
	// `visibleSlidesIndexes` refers to the range of slides that should show up when the right arrow is clicked
	// These values get plugged into array.slice, so the element at `visibleSlidesIndexes.end` doesn't
	// technically show up on screen
	const [visibleSlidesIndexes, setVisibleSlidesIndexes] = useState({start: 0, end: PAGE_SIZE});
	const [showModal, setShowModal] = useState(false);
	const [showPlaylistModal, setShowPlaylistModal] = useState(false);
	const [message, setMessage] = useState('');
	const [loop, setLoop] = useState(true);
	const [slideToEdit, setSlideToEdit] = useState(null);
	const [enabledButtons, setEnabledButtons] = useState(false);

	const [showSaveMessageModal, setShowSaveMessageModal] = useState(false);
	const [showErrorMessageModal, setShowErrorMessageModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showWarningMessageModal, setShowWarningMessageModal] = useState(false);
	const [warningMessage, setWarningMessage] = useState('');
	const [fetchFromApi, setFetchFromApi] = useState(true);

	const [apiMaxPage, setApiMaxPage] = useState(0);

	const [customer, setCustomer] = useState({});
	const [playlistType, setPlaylistType] = useState(null);

	/* DND-Kit related code */
	const pointerSensor = useSensor(PointerSensor, {
		// Require the mouse to move by 20 pixels before activating
		activationConstraint: {
			distance: 20
		},
	});
	const sensors = useSensors(pointerSensor);
	/* End DND-Kit related code */

	useEffect(() => {
		getCustomerData(userData.customerId)
		.then(data => {
			playlistOptions.types.forEach((type) => {
				if (data.enabledDash2Navs.includes(type.id)) {
					setPlaylistType(type);
				}
			})
		})
		.catch((error) => {
			console.error('Something went wrong:', error);
		});

		return;
	}, []);

	useEffect(() => {
		getPlaylistData(userData.customerId)
		.then(data => {
			setPlaylists(data)
		})
		.catch((error) => {
			console.error('Something went wrong:', error);
		});

		return;
	}, []);


	useEffect(() => {
		const getSlidesData = async () => {
			const url = `${process.env.REACT_APP_BIZ_BASE_URL}/api/slides/getSlidesByCustomerId/${userData.customerId}/${page}/${PAGE_SIZE}`;
			return await fetch(url)
			.then(response => response.json())
			.then(data => {
				// Remember max page and number of slides that API can return
				setApiMaxPage(parseInt(data.maxAvailablePages));

				// Collect the current slide IDs
				var currentSlideIds = slides.map((slide) => slide.id);
				// Concat the current set of slides with the new one.
				var updatedSlides = [...slides];
				data.slides.forEach((slide) => {
					if (!currentSlideIds.includes(slide.id)) {
						updatedSlides.push(slide);
					}
				})
				setSlides(updatedSlides);
				setFetchFromApi(false);

				return;
			})
			.catch((error) => {
				console.error('Something went wrong:', error);
			});
		}

		setFetchFromApi(true);
		getSlidesData();

		// If data hasn't been retrieved after some seconds, remove the loading icons.
		setTimeout(() => setFetchFromApi(false), 3000);

		return;
	}, [page, playlistSlides, showModal]);

	const shortenLabel = (label) => {
		const maxLength = window.innerWidth > 1200
		? 15
		: window.innerWidth > 950
		? 9
		: 5

		return label.length < maxLength ? label : `${label.slice(0, maxLength)}...`;
	}

	const editImage = (slide) => {
		setSlideToEdit(slide);
		setShowModal(true);
	}

	const newImage = () => {
		setSlideToEdit(null);
		setShowModal(true);
	}

	const getLinkTile = (slide, classList = '') => {
		let prefix = classList.includes('draggable-link') ? 'draggable-link' : 'droppable-link';
		return <div className={classList}>
			<div className="draggable-link--link-icon"><img src={linkIcon} height="45px" /></div>
			<div className={`proxima-bold ${prefix}--label`}>{slide.label}</div>
		</div>
	}

	const addSlideCodeBlock = (key, withBorder = true) => {
		return <div className="slide-container pure-u-1-6" key={key}>
			<div className="new-slide slide-dimensions" onClick={() => newImage()}>
				<div className={withBorder ? "new-slide-border" : ''}>
					<img src={addImage} id="add-image-icon" />
				</div>
			</div>
			<p style={{visibility: 'hidden'}}>hidden</p>
		</div>
	}

	//Gets the 3 slides showing on content library
	const getVisibleSlides = () => {
		//We get 6 items from BIZ if page is 0 and at most 9 items if page is bigger than 0
		// Hence the starting page will always be at 0 (when there are 6 items so no prev slides)
		// or 1 (when there are 3 prev slides and 3 next slides)
		const startingPage = page === 0 ? 0 : 1;

		let visibleSlides = slides.slice(visibleSlidesIndexes.start, visibleSlidesIndexes.end);
		//Push dummy slides for design
		while (visibleSlides.length < PAGE_SIZE) {
			visibleSlides.push({label: ''})
		}

		return visibleSlides.map((slide, index) => {
			let codeBlock = addSlideCodeBlock(index, false);

			if (fetchFromApi) {
				codeBlock = <div className="slide-container pure-u-1-6" key={index}>
					<div className="new-slide slide-dimensions">
							<div className="loading-slide"> <Oval height="50" width="50" color="#2386EE" secondaryColor="#a2ccf8" ariaLabel="images-loading" visible={true} />
							</div>
					</div>
					{
						slide?.label
						? (slide.label.toLowerCase() === '' && fetchFromApi)
							? <div className="slide-description">Loading...</div>
							: <div className="slide-description">{shortenLabel(slide?.label)}</div>
						: <div className="slide-description" style={{visibility: 'hidden'}}>hidden</div>
					}
				</div>
			}

			if (slide.url) {
				codeBlock = <div className="slide-container pure-u-1-6" key={index}>
					<Draggable id={slide.id} key={slide.id} data={slide} allowDrag={slide?.url} onClick={() => editImage(slide)} className="slide-dimensions">
						{ slide.url.includes(process.env.REACT_APP_S3_MESSAGING_BUCKET) ? <IFrame url={slide.url} /> : getLinkTile(slide, "draggable-link") }
					</Draggable>
					{
						slide?.label
						? (slide.label.toLowerCase() === '' && fetchFromApi)
							? <div className="slide-description">Loading...</div>
							: <div className="slide-description">{shortenLabel(slide?.label)}</div>
						: <div className="slide-description" style={{visibility: 'hidden'}}>hidden</div>
					}
				</div>
			}

			return codeBlock;
		})
	}

	const getNextSlide = () => {
		var start = visibleSlidesIndexes.start;
		var end = visibleSlidesIndexes.end;

		setVisibleSlidesIndexes({start: start + 1, end: end + 1});

		// If we
		// - need more slides than what we currently have
		// - and we haven't maxed out the pages the API can return
		// Then: check Biz.
		if (end + 1 === slides.length && page + 1 <= apiMaxPage) {
			// setPage will cause `getSlidesData` to run.
			setPage(page + 1);
		}
	}

	const getPrevSlide = () => {
		var start = visibleSlidesIndexes.start;
		var end = visibleSlidesIndexes.end;
		if (start - 1 >= 0) {
			setVisibleSlidesIndexes({start: start - 1, end: end - 1});
		}
	}

	function handleDragEnd(ev) {
		if (!ev.over) return;

		const slideId = ev?.active?.id;
		const index = ev?.over?.data?.current?.index;

		let tempPlaylistSlides = [...playlistSlides];
		//Placeholder Transit slide
		if (slideId === 'transit-placeholder') {
			tempPlaylistSlides[index] = {
				...tempPlaylistSlides[index],
				//Default placeholder slide dD
				slideId: 'd8431f1b-aca5-458c-8ff2-0a4ff118a9ac',
				playlistId: selectedPlaylist?.playlist?.id,
				url: 'transit-placeholder',
				type: 'transit-placeholder'
			}
		}
		else {
			const slide = slides.find(slide => slide.id === slideId);
			tempPlaylistSlides[index] = {
				...tempPlaylistSlides[index],
				slideId: slide.id,
				playlistId: selectedPlaylist?.playlist?.id,
				url: slide.url,
				label: slide.label
			}
		}

		//If loop is turned off, set the last duration to null
		if (!loop) {
			tempPlaylistSlides = setLoopDuration(tempPlaylistSlides, PLAYLIST_SLIDE_NUMBER);
		}

		setEnabledButtons(true);
		setPlaylistSlides(tempPlaylistSlides)
	}
	
	const selectPlaylist = (value) => {
		setSelectedPlaylist(value);

		let tempPlaylistSlides = [...playlistSlides];

		tempPlaylistSlides.forEach((ps, index) => {
			const psFromBiz = value.playlistSlides.find(item => item.sortOrder == ps.sortOrder);

			//Fill with BIZ playlistSlide, if not set the playlistSlide to null
			tempPlaylistSlides[index] = psFromBiz ? psFromBiz : {
				sortOrder: index + 1,
				//If not existing, just default back to 10 seconds
				durationMs: 10000
			};
		})

		const lastSlide = tempPlaylistSlides.findLast(ps => ps.slideId);
		//Check if empty playlist
		if (!lastSlide) {
			// tempPlaylistSlides = setLoopDuration(tempPlaylistSlides, PLAYLIST_SLIDE_NUMBER);
			setLoop(true);
		}
		//Check if this is a loop or not
		else if (!lastSlide?.durationMs) {
			setLoop(false);
		}
		else {
			setLoop(true);
		}
		
		setPlaylistSlides(tempPlaylistSlides);
		//Preparing for user clicking the Undo button
		setInitialPlaylistSlides(tempPlaylistSlides);
		// New playlist, therefore no changes yet.
		setEnabledButtons(false);
	}

	const selectDurationMs = (val, index) => {
		let psArr = [...playlistSlides];
		psArr[index].durationMs = val;
		setPlaylistSlides(psArr)
		setEnabledButtons(true);
	}

	const displayDurationOption = (durationMs) => {
		const label = DURATION_OPTIONS.find(option => option.value === durationMs)?.label;
		return {
			label,
			value: durationMs
		}
	}

	const confirmUndoChanges = () => {
		setWarningMessage('This will remove all changes since the last time you saved this playlist. Are you sure?');
		setShowWarningMessageModal(true);
	}

	const handleUndoChanges = () => {
		setShowWarningMessageModal(false);
		setPlaylistSlides(initialPlaylistSlides);
		setEnabledButtons(false);
	}

	const handleSubmit = async () => {
		const filteredPlaylistSlides = filterAndReorder(playlistSlides);
		await createOrUpdatePlaylistSlide(filteredPlaylistSlides)
		.then(resp => {
			if (resp.status === 200) {
				setShowSaveMessageModal(true);
				//Update current playlistSlides?
				getPlaylistData(userData.customerId)
				.then(data => {
					//Find the BIZ playlist to update current DASH2 data by
					const updatedPlaylist = data.find(d => d.playlist.id === selectedPlaylist?.playlist?.id);
					const updatedPlaylistSlides = updatedPlaylist?.playlistSlides;
					// Sort
					updatedPlaylistSlides.sort((a, b) => {
						return Number(a.sortOrder) - Number(b.sortOrder);
					});

					// Fill out the rest of the playlist slides array with placeholders
					var sliceAmount = PLAYLIST_SLIDE_NUMBER - updatedPlaylistSlides.length;
					let playlistSlidesForUI = updatedPlaylistSlides;
					if (sliceAmount > 0) {
						playlistSlidesForUI = updatedPlaylistSlides.concat(DEFAULT_PLAYLIST_SLIDES.slice(-sliceAmount));
					}

					setPlaylistSlides(playlistSlidesForUI);
					setPlaylists(data);
					setEnabledButtons(false);
				})
				.catch((error) => {
					console.error('Something went wrong:', error);
					// I don't know what state the playlist is in, but make sure the buttons are activated
					setEnabledButtons(true);
				});
			}
			else {
				setShowErrorMessageModal(true);
				// I don't know what state the playlist is in, but make sure the buttons are activated
				setEnabledButtons(true);
			}
		});
	}

	const handlePlaylistRemoval = async () => {
		if (!selectedPlaylist?.playlist) return;

		const status = await removePlaylist(selectedPlaylist?.playlist?.id);
		//If success, set all values back to the default settings
		if (status?.status === 200) {
			// Modify the contents of the Playlists dropdown
			var tempPlaylists = [];
			playlists.forEach((playlist) => {
				if (selectedPlaylist.playlist.id !== playlist.playlist.id) {
					tempPlaylists.push(playlist);
				}
			});

			setMessage(`Successfully removed playlist`)
			setSelectedPlaylist({})
			setPlaylistSlides(DEFAULT_PLAYLIST_SLIDES)
			setInitialPlaylistSlides(DEFAULT_PLAYLIST_SLIDES)
			setEnabledButtons(false);
			setPlaylists(tempPlaylists);
		}
		else {
			setMessage(`Failed to remove playlist)`);
		}
	}

	const removeSlideFromPlaylistSlide = (index) => {
		let tempPlaylistSlides = [...playlistSlides];
		//Revert playlist slide to default state
		tempPlaylistSlides[index] = {
			id: tempPlaylistSlides[index]?.id,
			sortOrder: index + 1,
			durationMs: tempPlaylistSlides[index]?.durationMs
		}

		//If loop is turned off, set the last duration to null
		if (!loop) {
			tempPlaylistSlides = setLoopDuration(tempPlaylistSlides, PLAYLIST_SLIDE_NUMBER);
		}

		setPlaylistSlides(tempPlaylistSlides);
		setEnabledButtons(true);
	}

	const onClickLoop = () => {
		const tempPlaylistSlides = [...playlistSlides];
		let lastPs = {};
		//Find the last playlist slide with content
		for (let i = PLAYLIST_SLIDE_NUMBER - 1; i >= 0; i--) {
			if (tempPlaylistSlides[i]?.url && !lastPs?.url) {
				lastPs = tempPlaylistSlides[i]
			}
			//If this playlist slide has duration set to permanent, set it back to default 10sec
			else if (tempPlaylistSlides[i]?.durationMs === null) {
				tempPlaylistSlides[i].durationMs = 10000
			}
		}
		//If no loop, set the last slide to null so it doesn't repeat 
		if (loop) {			
			lastPs.durationMs = null;
			setPlaylistSlides(tempPlaylistSlides)
		}
		//If loop, set the last slide back to a default duration
		else {
			lastPs.durationMs = 10000;
			setPlaylistSlides(tempPlaylistSlides)
		}	
		setLoop(!loop)
		setEnabledButtons(true);
	}

	const filterAndReorder = () => {
		var tempPlaylistSlides = [];
		var toDelete = [];
		var countPlaceholderSlides = 0;
		var expectedSortOrder = 1;
		playlistSlides.forEach((ps, index) => {
			if (!ps.slideId && ps.id) {
				// This slide is going to be deleted
				toDelete.push(ps);
			} else if (ps.slideId) {
				if (ps.sortOrder !== expectedSortOrder) {
					ps.sortOrder = expectedSortOrder;
				}
				tempPlaylistSlides.push(ps);
				expectedSortOrder++;
			} else {
				countPlaceholderSlides++;
			}
		});

		// Modify the toDelete sortOrder. We must avoid a situation where
		// two or more playlistSlides have the same sortOrder, even though
		// they are supposed to be deleted.
		toDelete.forEach((ps) => {
			ps.sortOrder = expectedSortOrder;
			expectedSortOrder++;
			tempPlaylistSlides.push(ps);
		});

		return tempPlaylistSlides;
	}

	const droppableDisabled = (index) => {
		var playlistExists = selectedPlaylist?.playlist !== undefined ? true : false;
		var maxSlidesExceeded = playlistType?.slides !== null && index >= playlistType?.slides ? true : false;

		if (playlistExists && maxSlidesExceeded) {
			return true;
		} else {
			return false;
		}
	}

	const noPlaylistSelected = () => {
		return selectedPlaylist.playlist === undefined ? true : false;
	}

	return (
		<div className="ContentManagementPage">
			{
				showModal
				? <SlideModal setShowModal={setShowModal} customerId={userData.customerId} slideToEdit={slideToEdit} playlists={playlists} updatedBy={userData?.nickname} />
				: <></>
			}
			{
				showPlaylistModal
				? <PlaylistModal setShowPlaylistModal={setShowPlaylistModal} customerId={userData.customerId} selectPlaylist={selectPlaylist} setPlaylists={setPlaylists} />
				: <></>
			}
			{
				showSaveMessageModal
				? <SaveMessageModal setShowSaveMessageModal={setShowSaveMessageModal} />
				: <></>
			}
			{
				showErrorMessageModal
				? <ErrorMessageModal setShowErrorMessageModal={setShowErrorMessageModal} errorMessage={errorMessage} />
				: <></>
			}
			{
				showWarningMessageModal
				? <WarningMessageModal
					setShowWarningMessageModal={setShowWarningMessageModal}
					warningMessage={warningMessage}
					handleUndoChanges={handleUndoChanges} />
				: <></>
			}
			<DndContext onDragEnd={handleDragEnd} sensors={sensors}>
				<div className="content-library-container">
					<h3 className="content-library-title">Content Library</h3>
					<div className="content-library-description">This library houses the images you would like to use in Playlists. Simply press any tile to add, edit, or clear its contents.</div>
					{/* slides are here */}
					<div className="slides-container pure-g">
						<div className="slide-container transit-slide-container pure-u-4-24">
							<Draggable id={'transit-placeholder'} key={'transit-slide'} allowDrag={true} className="slide-dimensions">
								<>
									<img id="transitInfoIcon" src={transitIcon} />
									<div id="transitInfoLabel">Transportation<br/>Information</div>
								</> 
							</Draggable>
							<div className="slide-description">Content unique to each Screen or Mobile</div>
						</div>
						<div className={`scroll-icon-container scroll-left pure-u-1-24 slide-dimensions ${visibleSlidesIndexes.start === 0 ? 'disabled-icon' : ''}`} onClick={getPrevSlide}>
							<div className="icon-box left-icon-box">
								<img className="icon" src={ visibleSlidesIndexes.start === 0 ? arrowLeftDisabled: arrowLeft } />
							</div>
						</div>
						{
							slides
							//Always show 3 at most
							? getVisibleSlides()
							: null
						}
						{addSlideCodeBlock('nokey')}
						<div className={`scroll-icon-container scroll-right pure-u-1-24 slide-dimensions ${ visibleSlidesIndexes.end < slides.length ? '' : 'disabled-icon' }`} onClick={getNextSlide}>
							<div className="icon-box right-icon-box">
								<img className="icon" src={ visibleSlidesIndexes.end < slides.length ? arrowRight : arrowRightDisabled } />
							</div>
						</div>	
					</div>		
				</div>
				<div className="playlists-container">
					<h3>Playlists</h3>
					<p>Drag and drop items from your Content library into your Playlist. Once a Playlist is created you may assign it to any of your active Screens within "My Screens".</p>
					<div className="options-container">
						<p>Playlist name:</p>
						<Select  
							onChange={(e) => selectPlaylist(e.value)}
							options={playlists.map(pl => {
								return {
									value: pl,
									label: `${pl.playlist.label} (${pl.playlist.shortCode})`
								}
							})}
							value={
								selectedPlaylist?.playlist
								? {
									value: selectedPlaylist,
									label: `${selectedPlaylist?.playlist?.label} (${selectedPlaylist?.playlist?.shortCode})`
								}
								: {}
							}
							className={"dropdown"}
						/>
						<div className="action-icon-container " title="Delete playlist" onClick={() => handlePlaylistRemoval(selectedPlaylist?.playlist?.id)}>
							<img className="icon" src={deleteIcon} />
						</div>
						<div className="action-icon-container" title="Create playlist" onClick={() => setShowPlaylistModal(true)}>
							<img className="icon" src={plusIcon} />
						</div>
						<div className="loop-container">
							<p className="label">Loop playlist:</p>
							<Switch className="switch" checked={playlistType?.loop ? true : loop } disabled={playlistType?.loop ? true : false } onChange={onClickLoop} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>
						</div>
					</div>
					<div className={`drop-container pure-g ${noPlaylistSelected() ? 'disable-droppable-area' : ''}`}>
					{
						playlistSlides.map((ps, index) => {
							return <div className={`slide-container pure-u-4-24 ${droppableDisabled(index) ? 'disable-droppable': ''}`} key={index}>
								<div className="slide-number">Slide {index + 1}</div>

								<Droppable id={`droppable-${index}`} key={index} index={index} disabled={droppableDisabled(index) || noPlaylistSelected()} className="droppable-slide-dimensions slide-dimensions">
										{
											ps?.slideId
											? <div className="remove-slide" onClick={() => removeSlideFromPlaylistSlide(index)}>&#x2715;</div>
											: <div className="remove-slide"></div>
										}
										{
											//Special transit case image... may be a temporary measure
											// while we figure out how BIZ handles placeholder slides
											ps?.url === 'transit-placeholder' || ps?.slideId === 'd8431f1b-aca5-458c-8ff2-0a4ff118a9ac' || (selectedPlaylist?.slide && selectedPlaylist?.slide?.id === ps?.slideId)
											? <>
												<img id="transitInfoIcon" src={transitIcon} />
												<div id="transitInfoLabel">Transportation<br/>Information</div>
											</>
											: (
												// Is there a playlistSlide URL? Determine if it's image hosted in S3 or an external link
												ps?.url
												? (ps.url.includes(process.env.REACT_APP_S3_MESSAGING_BUCKET) ? <IFrame url={ps.url} /> : getLinkTile(ps, 'droppable-link') )
												: <div className="droppable-placeholder">
													<img src={dragIcon} />
													<div>Drag content here</div>
												</div>
											)
										}								
								</Droppable>
								<Select
									isDisabled={ps?.slideId ? '': 'disabled'}
									onChange={(e) => selectDurationMs(e.value, index)}
									options={DURATION_OPTIONS}
									value={displayDurationOption(ps?.durationMs)}
									isOptionDisabled={(option) => option.disabled}
									className={"duration-dropdown"}
									key={`ps-${index}`}
									maxMenuHeight={100}
								/>
							</div>
						})
					}
					</div>
					<div className="buttons-container">
						<button
							onClick={() => confirmUndoChanges()}
							className={`secondary-button ${enabledButtons ? '' : 'disabled-button'}`}
							disabled={!enabledButtons}>
							Undo changes
						</button>
						<button
							onClick={handleSubmit}
							className={`${enabledButtons ? 'primary-button' : 'disabled-button'}`}
							disabled={!enabledButtons}>
							Save playlist
						</button>
					</div>
				</div>
			</DndContext>
		</div>
	)
}

const IFrame = ({ url }) => {
	const ref = useRef();

	return <img src={url} ref={ref} className="slide-content"></img>
}

export function PlaylistModal({ setShowPlaylistModal, customerId, selectPlaylist, setPlaylists }) {

	const [playlistName, setplaylistName] = useState('');

	const handlePlaylistCreation = async () => {
		const body = {
			customerId,
			label: playlistName,
			access: 'public',
			type: 'content',
			product: 'screen'
		}
		await createPlaylist(body)
		.then(async data => {

			await getPlaylistData(customerId)
			.then(playlists => {
				setPlaylists(playlists);
				//Find the BIZ playlist to update current DASH2 data by
				const updatedPlaylist = playlists.find(pl => pl.playlist.id === data?.id);
				selectPlaylist(updatedPlaylist)
			})
			.catch((error) => {
				console.error('Something went wrong:', error);
			});
			setShowPlaylistModal(false);
		})		
	}

	return <div className="Modal">
		<div className="ModalWindow PlaylistModal">
			<div className="input-container">
				<div className="modal-label">Playlist name:</div>
				<div className="modal-input">
					<input value={playlistName} onChange={e => setplaylistName(e.target.value)} placeholder="Give this playlist an easy to remember name." maxLength={"120"}></input>
				</div>
			</div>
			<div style={{display: "flex",  justifyContent: "flex-end", color: "#b7bfc1"}}>Maximum 120 characters</div>
			<div className="buttons-container">
				<button className="secondary-button" onClick={() => setShowPlaylistModal(false)} >Cancel</button>
				<button className={playlistName === '' ? "secondary-button disabled-button" : "primary-button"} onClick={() => handlePlaylistCreation()} disabled={playlistName === '' ? true : false}>Create</button>
			</div>
		</div>
	</div>
}


const SaveMessageModal = ({setShowSaveMessageModal}) => {
	return <div className="Modal">
		<div className="ModalWindow playlist-crud-message" style={{justifyContent:"center"}}>
			Your playlist has been saved.
			<div className="buttons-container" style={{justifyContent: "center"}}>
				<button className="primary-button" onClick={() => setShowSaveMessageModal(false)}>Okay</button>
			</div>
		</div>
	</div>
};

const ErrorMessageModal = ({setShowErrorMessageModal, errorMessage}) => {
	return <div className="Modal">
		<div className="ModalWindow playlist-crud-message" style={{justifyContent:"center"}}>
			<span style={{color: "red"}}>{ errorMessage ?? "Error saving playlist. Please try again."}</span>
			<div className="buttons-container">
				<button className="primary-button" onClick={() => setShowErrorMessageModal(false)}>Okay</button>
			</div>
		</div>
	</div>
};

const WarningMessageModal = ({setShowWarningMessageModal, warningMessage, handleUndoChanges}) => {
	return <div className="Modal">
		<div className="ModalWindow playlist-crud-message">
			{ warningMessage }
			<div className="buttons-container">
				<button className="secondary-button" onClick={() => setShowWarningMessageModal(false)}>Cancel</button>
				&nbsp;
				<button className="primary-button" onClick={() => handleUndoChanges()}>Undo changes</button>
			</div>
		</div>
	</div>
};

export default ContentManagementPage
