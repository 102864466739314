import React from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import Login from './Login';

import en from './localization/en.json';

import { oktaSignInConfig } from './config';

const AppWithRouterAccess = () => {
    const history = useHistory();

    const customAuthHandler = () => {
        history.push('/login');
    };

    oktaSignInConfig.onAuthRequired = customAuthHandler;

    const oktaAuth = new OktaAuth(oktaSignInConfig);

    // Determine language localization at this level
    const lang = en;

    return (
        <Security oktaAuth={oktaAuth}>
            <Route path='/'><Login lang={lang} /></Route>
            <Route path='/login' render={() => <Login />} />
            <Route path='/login/callback' component={LoginCallback} />
        </Security>
    );
};
export default AppWithRouterAccess;
