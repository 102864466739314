import { useState, useEffect, useRef } from 'react';
import { Circles } from 'react-loader-spinner';
import { v4 as uuidv4 } from 'uuid';
import {getSlideUploadUrl, uploadSlideToAws, createSlide, updateSlide, removeSlide } from './controller';
import addImage from '../../images/icons/add-image.svg';
import uploadIcon from '../../images/icons/upload.svg';
import templateIcon from '../../images/icons/template.svg';
import imageIcon from '../../images/icons/image.svg';
import linkIcon from '../../images/icons/link.svg';

export default function SlideModal({ setShowModal, customerId, slideToEdit, playlists, updatedBy }) {
	const [uploadedImage, setUploadedImage] = useState({
		file: null,
		name: null,
		type: null,
		url: null
	});
	const [slideName, setSlideName] = useState('');
	const [slidePlaylists, setSlidePlaylists] = useState([]);
	const [slide, setSlide] = useState(slideToEdit);
	const [typeError, setTypeError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const dropFile = useRef(null);
	const fileInput = useRef(null);
	const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
	const [confirmDeleteMessage, setConfirmDeleteMessage] = useState('');
	const [enabledButtons, setEnabledButtons] = useState(false);
	const [required, setRequired] = useState(false);
	const [slideType, setSlideType] = useState('image');
	const [externalUrl, setExternalUrl] = useState('');

	useEffect(() => {
		if (slideToEdit?.url) {
			setSlideName(slideToEdit.label);
			if (slideToEdit.url.includes(process.env.REACT_APP_S3_MESSAGING_BUCKET)) {
				var urlParts = slideToEdit.url.split('.');
				setUploadedImage({...uploadedImage, name: slideToEdit.label, url: slideToEdit.url, type: urlParts.pop()});
			}
			if (!slideToEdit.url.includes(process.env.REACT_APP_S3_MESSAGING_BUCKET)) {
				setSlideType('link');
				setExternalUrl(slideToEdit.url);
			}

			// Determine what playlists the current slide belongs to
			if (playlists.length > 0) {
				var currentSlidePlaylists = [];
				playlists.forEach((playlist, index) => {
					playlist.playlistSlides.forEach(pSlide => {
						if (pSlide.slideId === slideToEdit.id) {
							currentSlidePlaylists.push(playlists[index]);
						}
					});
				});
				setSlidePlaylists(currentSlidePlaylists);
			}
		}
	}, [slideToEdit]);

	useEffect(() => {
		//Add event lister for file upload
		dropFile.current.addEventListener('dragover', handleDragOver);
		dropFile.current.addEventListener('drop', handleDrop);
	  
		return () => {
			dropFile.current.removeEventListener('dragover', handleDragOver);
			dropFile.current.removeEventListener('drop', handleDrop);
		};
	}, []);

	const onUpload = async (upload) => {
		const file = upload[0];
		let validFileTypes = ['image/jpeg', 'image/jpg', 'image/gif', 'image/svg+xml', 'image/png', 'image/webp'];
		if (!validFileTypes.includes(file.type)) {
			setTypeError(true);
			return;
		}
		const objectUrl = URL.createObjectURL(file);
		setUploadedImage({
			file: file,
			name: file.name,
			type: file.type.split('/')[1],
			url: objectUrl
		});
		setTypeError(false);
		setEnabledButtons(true);
		setRequired(false);
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
	  
		const {files} = e.dataTransfer;
	  
		if (files && files.length) {
		  onUpload(files);
		}
	};

	const handleClick = () => {
		fileInput.current.click();
	};
	
	const closeModal = () => {
		setSlideName('');
		setUploadedImage({
			file: null,
			name: null,
			type: null,
			url: null
		});
		setShowModal(false);	
	}

	const saveSlide = async (event) => {
		if (!slideName) {
			setRequired('slideLabel');
			return;
		}
		if (slideType === 'image' && !uploadedImage.type) {
			setRequired('slideImage');
			return;
		}
		if (slideType === 'link' && externalUrl === '') {
			setRequired('externalUrl');
			return;
		}

		// Show loading screen while processing
		setLoading(true);

		var s3Url = '';

		// Upload to AWS if a new image was uploaded, regardless
		// of whether we're creating or editing a slide.
		if (slideType === 'image' && uploadedImage.url !== slide?.url) {
			// Eg. uploadedImage.file.type = image/jpeg
			const fileType = uploadedImage.file.type.split('/')[0];
			const fileFormat = uploadedImage.file.type.split('/')[1];

			const slideKey = uuidv4();
			const awsUrl = await getSlideUploadUrl(customerId, slideKey, fileType, fileFormat)
			.then(async resp => {
				await uploadSlideToAws(resp, uploadedImage.file, uploadedImage.file.type)
				.then(async response => {
					//Successful upload to s3
					if (response.status === 200) {
						s3Url = `${process.env.REACT_APP_AWS_URL}/${customerId}/${slideKey}.${fileFormat}`;
						if (s3Url.includes('.svg+xml')) {
							s3Url = s3Url.replace('svg+xml', encodeURIComponent('svg+xml'));
						}
					} else {
						setErrorMessage('There were issues uploading to S3. Status code: ' + response.status);
						setLoading(false);
						return;
					}
				})
			});
		}

		// New slide
		if (!slide?.url) {
			var slideUrl = (slideType === 'image') ? s3Url : externalUrl;
			await createSlide({
				createdBy: updatedBy,
				customerId,
				label: slideName,
				url: slideUrl,
				type: slideType,
				updatedBy
			});
		} else if (slide) {
			var doUpdate = false;
			// Update the slide name
			if (slideName !== slide.label) {
				slide.label = slideName;
				doUpdate = true;
			}
			// Update the image.
			// There is a new image in S3, so delete the old one.
			if (slideType === 'image' && s3Url !== '') {
				slide.urlToDelete = slide.url;
				slide.url = s3Url;
				doUpdate = true;
			}
			// Update the link
			if (slideType === 'link' && externalUrl !== slide.url) {
				// If switching from image type to link type, delete the S3 url.
				if (slide.url.includes(process.env.REACT_APP_S3_MESSAGING_BUCKET)) {
					slide.urlToDelete = slide.url;
				}
				slide.url = externalUrl;
				doUpdate = true;
			}

			if (doUpdate) {
				slide.updatedBy = updatedBy;
				await updateSlide(slide.id, slide)
				.then(async response => {
					if (response.status === 200) {
						console.log('Successfully updated slide');
					} else {
						setErrorMessage('There were issues updating the slide. Status code: ' + response.status);
						setLoading(false);
						return;
					}
				})
			}
		}

		// Show content again
		setLoading(false);
		setShowModal(false);
	}

	const updateSlideName = (e) => {
		if (e.target.value.length > 0) {
			setRequired(false);
		}
		setSlideName(e.target.value);
		setEnabledButtons(true);
	}

	const getUploadTemplate = () => {
		return <>
			<img className="icon" src={addImage} />
		</>
	}

	const confirmDeleteSlide = async () => {
		var message = '';
		if (slidePlaylists.length > 0) {
			message = 'This content is used by one or more playlists. If you proceed, this content will be removed from all affected playlists. This action cannot be undone.';
		} else {
			message = 'If you proceed, this content will be removed. This action cannot be undone.';
		}
		slidePlaylists.forEach((sPlaylist) => {
			console.log(sPlaylist.playlist.label);
		});
		setConfirmDeleteMessage(message);
		setShowConfirmDeleteModal(true);
	}

	const deleteSlide = async () => {
		setUploadedImage({
			file: null,
			name: null,
			type: null,
			url: null
		});
		// Delete slide from Slides and PlaylistsSlides tables
		await removeSlide(slide.id);
		setSlideName('');
		setShowModal(false);
		window.location.reload(true);
	}

	const updateSlideType = (type) => {
		setSlideType(type);
		setEnabledButtons(true);
	}

	return <div className="Modal">
		{
			showConfirmDeleteModal ?
			<ConfirmModal message={confirmDeleteMessage} setShowConfirmDeleteModal={setShowConfirmDeleteModal} deleteSlide={deleteSlide} /> :
			<></>
		}
		<div className="ModalWindow">
			{
				loading
				? <div className="Loading">
					<Circles
						height="80"
						width="80"
						color="#2386EE"
						ariaLabel="circles-loading"
						visible={true}
					/>
				</div>
				: <>
					<h1>Content library item</h1>
					<div>This is where you create, edit, or delete this content item. </div>
					<div className="choices">
						<div className="choice pure-g">
							<div className="radio-button pure-u-1-24">
								<input type="radio" name="slide_content" value="image" checked={slideType === 'image'} onChange={(e) => updateSlideType('image')}/>
							</div>
							<div className="img-icon pure-u-1-24">
								<img src={imageIcon}></img>
							</div>
							<div className="label pure-u-2-24">
								Image
							</div>
							<div className="pure-u-20-24">
								<div className="slide-container pure-g">
									<div className={`add-slide-container pure-u-21-24 ${required === "slideImage" ? 'required' : ''}`}>
										<div className={`add-slide ${uploadedImage?.url ? 'no-border' : ''}`} ref={dropFile}>
										{
											uploadedImage?.url
											? <div className="uploaded-image">
												<img src={uploadedImage.url}></img>
											</div>
											: getUploadTemplate()
										}
										</div>
									</div>
									<div className="icon-column pure-u-3-24">
										<div className="icon-box" title="Upload image" style={{backgroundColor: 'var(--Blueberry, #2386EE)'}} onClick={handleClick}>
											<img className="icon upload-icon" src={uploadIcon} />
											<input
												type="file"
												onChange={e => onUpload(e.target.files)}
												ref={fileInput}
												style={{display: 'none'}} // Make the file input element invisible
											/>
										</div>
										<div className="icon-box" title="Use a template">
											<a target="_blank" href="https://docs.google.com/presentation/d/1dy7LaVqxg50_GGQ3y77jWiEWBMwqzoAA/edit?usp=sharing&ouid=113427087434766781389&rtpof=true&sd=true">
												<img className="icon" src={templateIcon} />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="choice pure-g">
							<div className="radio-button pure-u-1-24">
								<input type="radio" name="slide_content" value="link" onChange={(e) => updateSlideType('link')} checked={slideType === 'link'} />
							</div>
							<div className="img-icon pure-u-1-24">
								<img src={linkIcon}></img>
							</div>
							<div className="label pure-u-2-24">URL</div>
							<div className="pure-u-20-24">
								<div><input type="text" value={externalUrl} placeholder="https://" className={required === "externalUrl" ? 'required' : ''} onChange={e => setExternalUrl(e.target.value)}></input></div>
							</div>
						</div>
						<div className="input-guideline">Only use a secure https:// URL</div>
					</div>
					{
						typeError
						? <p className="error-text">Incorrect input type</p>
						: <p className="error-text" style={{visibility: 'hidden'}}>Hidden</p>
					}
					<p>Below please specify a short label which will show below this content item in your library.</p>
					<div className="input-container">
						<input className={required === "slideLabel" ? 'required' : ''} type="text" value={slideName} onChange={e => updateSlideName(e)} placeholder="Enter a short label" maxLength="120"></input>
					</div>
					<div className="input-guideline">
						Maximum 120 characters
					</div>			
					<div className="error-message">{errorMessage}</div>
					<div className="buttons-container">
						<button className="secondary-button" onClick={closeModal} title="Cancel changes">Cancel</button>
						<button className="secondary-button" onClick={confirmDeleteSlide} title="Delete slide">Delete</button>
						<button
							disabled={!enabledButtons}
							className={`${enabledButtons ? 'primary-button' : 'disabled-button'}`}
							onClick={(e) => saveSlide(e)}
							title="Save changes"
						>
							Save
						</button>
					</div>
				</>
			}

		</div>
	</div>
};

const ConfirmModal = ({message, setShowConfirmDeleteModal, deleteSlide}) => {
	return <div className="delete-slide-modal ModalWindow">
			<div>
				{message}
			</div>
			<div className="buttons-container">
				<button className="secondary-button" onClick={() => setShowConfirmDeleteModal(false)}>Cancel</button>
				<button className="primary-button" onClick={() => deleteSlide()}>Delete</button>
			</div>
	</div>
}
