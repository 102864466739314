import React, { useState, useEffect } from 'react';

// import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Circles } from 'react-loader-spinner';

import ContentSwitcher from './components/Dashboard/ContentSwitcher';
import Sidebar from './components/Dashboard/Sidebar';

import { config } from './config';
import { getCustomerData } from './utils';
import { Context } from './components/Context';

const Dashboard = ({lang, backgroundImage, user}) => {

    // const { authState, oktaAuth } = useOktaAuth();
    const [state, setState] = React.useContext(Context);
    const [cookies, setCookie, removeCookie] = useCookies([config.userCookieName]);

    // This is used to clear out the ?code= that Okta appends to URL
    // Be careful to verify this doesnt create a feedback loop with AppWithRouterAccess
    const history = useHistory();
    if (history.location.pathname === '/login') history.push("/");

    const [resetLogoutModal, setResetLogoutModal] = useState({open: false, modalType: 'reset'}); //the modal overlays the entire page, so this needs to leave high in the cimponent hierarchy

    useEffect(() => {
        async function fetchStateData(customerId) {
            await getCustomerData(customerId).then(data => {
                setState((prevState) => {
                    return {
                        ...prevState,
                        userData: {
                            ...user,
                            customerId: user.customer_id,
                            customerLogo: data.customerLogo
                        },
                        afCustomer: data,
                        customer: data
                    }
                })
            });
        }

        fetchStateData(user.customer_id);

        // if (!authState.isAuthenticated) {
        //     // When user isn't authenticated, forget any user info
        //     // This can happen when the token times out
        //     // oktaAuth.signOut();
        //     // removeCookie(config.userCookieName);
        // } else {
        //     const customField = authState.accessToken;

        //     const {                         
        //         aud,
        //         city,
        //         customer_id,
        //         department,
        //         organization,
        //         postalAddress,
        //         primaryPhone,
        //         role,
        //         seen_events,
        //         state,
        //         streetAddress,
        //         title,
        //         uid,      
        //         zipCode
        //     } = customField.claims;

        //     //TODO: We shoud consider implementing storage to compare against and only run below code if user changed
        //     oktaAuth.getUser().then((userInfo) => {

        //         const newUserInfo = {
        //             ...userInfo,
        //             accessToken: customField.accessToken,
        //             aud,
        //             city,
        //             customerId: customer_id,
        //             department,
        //             organization,
        //             postalAddress,
        //             primaryPhone,
        //             role,
        //             seen_events,
        //             state,
        //             streetAddress,                    
        //             title,
        //             uid,                  
        //             zipCode,                                        
        //         };
                
        //         async function fetchStateData(customerId) {
        //             await getCustomerData(customerId).then(data => {
        //                 setState((prevState) => {
        //                     return {
        //                         ...prevState,
        //                         userData: {
        //                             ...newUserInfo,
        //                             customerLogo: data.customerLogo
        //                         },
        //                         afCustomer: data,
        //                         customer: data
        //                     }
        //                 })
        //             });
        //         }

        //         fetchStateData(newUserInfo.customerId);
        //     });
        // }

    }, []); // Do it once

    //If user reloads on a page that needs customerId
    if(!state.userData || !state.userData.customerId){
		return <div className="Loading">
            <Circles
                height="80"
                width="80"
                color="#2386EE"
                ariaLabel="circles-loading"
                visible={true}
            />
        </div>
	}

    const isAdminMenu = (customer) => {
        //Actionfigure admins are the only ones to view Admin menu
        return state.userData.role === 'af_admin';
    }

    return (
        <div className="Dashboard pure-g" style={{ backgroundImage: `linear-gradient(101deg, rgba(255, 255, 255, 0.8) 22.93%, rgba(15, 29, 88, 0.8) 73.97%), url(${backgroundImage})`}}>

            <Sidebar
                className="pure-u-1 pure-u-md-1-6"
                setModal={setResetLogoutModal}
                lang={lang}
                adminMenu={isAdminMenu(state.customer)}
                customer={state.customer} />

            <ContentSwitcher
                className="pure-u-1 pure-u-md-5-6"
                setModal={setResetLogoutModal}
                lang={lang}
                adminMenu={isAdminMenu(state.customer)}
                userData={state.userData}
                customer={state.customer} />
        </div>
    );
};


export default Dashboard;
