import {
	Route,
} from 'react-router-dom';

import HomeLanding from './HomeLanding';
import ScreenPage from '../Screen/ScreenPage';
import NewScreenPage from '../Screen/NewScreenPage';

import ContentManagementPage from '../ContentManagement/ContentManagementPage'
import TempPlaylistCreationPage from '../TempPlaylistCreation/TempPlaylistCreationPage';
import ForesightPage from '../Foresight/ForesightPage';
import ReportAnalyticsPage from '../ReportAnalytics/ReportAnalyticsPage';
import MobilityScore from '../MobilityScore/MobilityScore';
import CustomersPage from '../Customers/CustomersPage';
import UsersPage from '../Users/UsersPage';

import logo from '../../images/logos/af-logo.svg'

import sidebarNavItems from '../../constants/sidebarNavItems.json';

const ContentSwitcher = ({
	className,
	lang,
	userData,
	customer,
	adminMenu
}) => {

	// Flatten nav items
	let allPages = [];
	sidebarNavItems.forEach((parent) => {
		if (parent.subNavItems) {
			parent.subNavItems.forEach((subItem) => {
				allPages.push(subItem);
			})
		}
		else {
			allPages.push(parent);
		}
	});
 
	// Assign "page" components to the nav list
	const pageRoutes = allPages.map((page) => {	
		if (page.component == 'HomeLanding') {
			page.jsx = <HomeLanding lang={lang} userData={userData}/>
		}
		else if (page.component == 'ScreenPage') {
			page.jsx = <NewScreenPage lang={lang} userData={userData}/>
		}
		else if (page.component == 'MobilePage') {
			page.jsx = <NewScreenPage lang={lang} userData={userData}/>
		}
		else if (page.component === 'TempPlaylistCreationPage') {
			page.jsx = <TempPlaylistCreationPage lang={lang} userData={userData}/>
		}
		else if (page.component == 'ReportAnalyticsPage') {
			page.jsx = <ReportAnalyticsPage lang={lang} accessToken={userData.accessToken}/>
		}
		else if (page.component == 'ForesightPage') {
			page.jsx = <ForesightPage lang={lang} accessToken={userData.accessToken}/>
		}
		else if (page.component == 'MobilityScore') {
			page.jsx = <MobilityScore lang={lang} userData={userData}/>
		}
		else if (page.component == 'UsersPage') {
			page.jsx = <UsersPage lang={lang} userData={userData}/>
		}
		else if (page.component == 'CustomersPage') {
			page.jsx = <CustomersPage lang={lang} userData={userData}/>
		}
		
		// if (!page.disabled) {
		// 	page.jsx = components[page.component];
		// } else {
		// 	//TODO Temporary measure as Foresight goes into the above if statement
		// 	//but for some reason keeps on going out on in this else statement
			
		// 	page.jsx = <ForesightPage lang={lang} accessToken={userData.accessToken}/>		
		// }

		return page;
	});

	return (
		<div className={`Content ${className || ''}`}>
			<div className="header-logo">
				<img id="actionfigure-logo" src={logo} alt="action figure logo"/>
			</div>
			<div className="container">
					{pageRoutes.map((page) => {
						return <Route key={page.key} exact={true} path={page.path}>
							{
								page.component == 'HomeLanding'
								? <HomeLanding lang={lang} userData={userData} customer={customer} />
								: page.component == 'ScreenPage'
								? <NewScreenPage
									lang={lang}
									userData={userData}
									access={'screen'}
									customer={customer}
									adminMenu={adminMenu} />
								: page.component == 'MobilePage'
								? <NewScreenPage
									lang={lang} 
									userData={userData}
									access={'mobile'}
									customer={customer}
									adminMenu={adminMenu} />
								: page.component == 'ContentManagementPage'
								? <ContentManagementPage lang={lang} userData={userData}/>
								: page.component == 'TempPlaylistCreationPage'
								? <TempPlaylistCreationPage lang={lang} userData={userData}/>
								: page.component == 'ReportAnalyticsPage'
								? <ReportAnalyticsPage customer={customer}/>
								: page.component == 'ForesightPage'
								? <ForesightPage lang={lang} userData={userData} customer={customer}/>
								: page.component == 'MobilityScore'
								? <MobilityScore lang={lang} userData={userData} />
								: page.component == 'UsersPage'
								? <UsersPage lang={lang} userData={userData} customer={customer} />
								: page.component == 'CustomersPage'
								? <CustomersPage lang={lang} userData={userData} customer={customer} />
								: <></>
							}
						</Route>
					})}
			</div>
		</div>
	);

};

export default ContentSwitcher
