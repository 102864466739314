import React, { useState, useEffect } from 'react';
import Layout from './components/Layout';
// import { useOktaAuth } from '@okta/okta-react';
import { useAuth0 } from "@auth0/auth0-react";
import { Circles } from 'react-loader-spinner';
import Dashboard from './Dashboard';

import visual1 from './images/login/login-visual1.jpg';
import visual2 from './images/login/login-visual2.jpg';
import visual3 from './images/login/login-visual3.jpg';
import visual4 from './images/login/login-visual4.jpg';
import visual5 from './images/login/login-visual5.jpg';
import visual6 from './images/login/login-visual6.jpg';
import visual7 from './images/login/login-visual7.jpg';

const Login = ({ lang }) => {
	// const { authState } = useOktaAuth();
	const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

	const visuals = [visual1, visual2, visual3, visual4, visual5, visual6, visual7];

	function getRandomInt(max) {
		return Math.floor(Math.random() * max);
	}

	const [backgroundImage, setBackgroundImage] = useState(visuals[getRandomInt(visuals.length)])

	useEffect(() => {
		if (isLoading || isAuthenticated) {
			return;
		}
		
		const fn = async () => {
			await loginWithRedirect({
			  appState: { targetUrl: window.location.origin }
			});
		};

		fn();
	}, [isLoading, isAuthenticated, loginWithRedirect, window.location.origin]);

	// if (authState.isPending) return null;
	return user
	? <Layout><Dashboard lang={lang} backgroundImage={backgroundImage} user={user} isLoading={isLoading} /></Layout> 
	: <div className="Loading">
		<Circles
			height="80"
			width="80"
			color="#2386EE"
			ariaLabel="circles-loading"
			visible={true}
		/>
	</div>	
};

export default Login;
