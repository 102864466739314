import { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { Circles } from 'react-loader-spinner';
import { createAuth0User, getAuth0Users, updateAuth0User, auth0PasswordReset } from './utils';

import checkIcon from '../../images/icons/check.svg';
import plusIcon from '../../images/icons/screens/white-plus.svg';
import saveIcon from '../../images/icons/screens/save.svg';

const UsersPage = ({
    lang,
	userData,
    customer
}) => {

    const [users, setUsers] = useState([]);
    const [editUser, setEditUser] = useState(null);
    const [stateChanged, setStateChanged] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);

    const getUsers = async () => {
        return await getAuth0Users(customer.id);
    }

    useEffect(() => {
        getUsers().then(data => {
            setUsers(data);
        })
    }, [])    

    const fillSvgColor = (svg, stroke, fill) => {
		if (stroke || fill) {
			for (var i = 0; i < svg.children.length; i++) {
                svg.children[i].setAttribute('stroke', stroke);
				svg.children[i].setAttribute('fill', fill);
            }
		}
	}

    const formatDateDigits = (num) => {
        return num < 10 ? `0${num}` : num;
    }

    const formatDate = (dateTime) => {
        const date = dateTime ? new Date(dateTime) : null
        return date
        ? `${formatDateDigits(date.getMonth() + 1)}/${formatDateDigits(date.getDate())}/${date.getFullYear()}`
        : null
    }

    const userClicked = (user) => {
        setEditUser(user);
        setShowUserModal(true);
    }

    const userRows = users.length === 0
	? <tr className="no-users-text"><td></td><td></td><td></td><td>No customers found</td></tr>
	: users.map(user => {
        //Check if af admin or 
        const isAdmin = user?.user_metadata?.role === 'af_admin' || user?.user_metadata?.role === 'admin';

		return <tr key={user?.user_id} className={`user-row`} onClick={() => userClicked(user)}>
			<td>{user?.user_id.split('|')[1]}</td>
            <td>{user?.given_name}</td>
            <td>{user?.family_name}</td>
            <td>{user?.email}</td>
            <td style={{textAlign: 'center'}}>{isAdmin ? <ReactSVG className="icon" src={checkIcon} /> : <></>}</td>            
            <td>{user?.user_metadata?.is_active ? 'Active' : 'Inactive'}</td>
            <td>{formatDate(user?.created_at)}</td>
            <td>{user?.user_metadata?.createdBy}</td>
		</tr>
	})

	return ( 
		<div className="UsersPage">
            { showUserModal && <UserModal setShowUserModal={setShowUserModal} customerId={userData.customerId} editUser={editUser} setEditUser={setEditUser} getUsers={getUsers} setUsers={setUsers} /> }
            <div className="user-actions">
                <div className="left-container">
                    <button className="open-user pure-u-1-9" onClick={() => setShowUserModal(true)}>
                        <img className="open-icon" src={plusIcon}/>
                    </button>
                    <div className="user-name pure-u-1-7">
                        <p style={{marginTop: 0, marginBottom: 0}}>{customer?.name}</p>
                        <p style={{marginTop: "0.5em"}}>{customer?.address1}</p>
                    </div>
                </div>
				<div className="user-tabs pure-u-1-2">
                    <div className={`icon-container ${stateChanged ? "changed" : ""}`} title="Save changes"  >
						<ReactSVG className="icon" src={saveIcon} beforeInjection={(svg) => stateChanged ? fillSvgColor(svg, null, '#fff') : null}/>
					</div>
				</div>
			</div>
            <div className="users-container">
                <h3>Users</h3>
                <table className="users-table">
                    <tbody>
                        <tr className="header-row">							
                            <th className="table-header">User ID</th>
                            <th className="table-header">First name</th>
                            <th className="table-header">Last name</th>
                            <th className="table-header">Email</th>
                            <th className="table-header" style={{textAlign: 'center'}}>Admin</th>
                            <th className="table-header">Status</th>
                            <th className="table-header">Created</th>
                            <th className="table-header">Created by</th>
                        </tr>
                        {userRows}
                    </tbody>
                </table>

            </div>
		</div>
	);
}

const UserModal = ({ setShowUserModal, customerId, editUser, setEditUser, getUsers, setUsers }) => {

    //Roles that are seen as admin
    const isAdmin = editUser?.user_metadata?.role === 'af_admin' || editUser?.user_metadata?.role === 'admin';

    const [user, setUser] = useState({
        email: editUser ? editUser?.email : "",
        firstName: editUser ? editUser?.given_name : "",
        isAdmin: editUser ? isAdmin : true,
        lastName: editUser ? editUser?.family_name : ""
    })
    const [loading, setLoading] = useState(false);
    const [resetStatus, setResetStatus] = useState(null);

    //Function to handle what user role is given
    const determineAdminRole = (isAdmin) => {
        //Admin box not checked
        if (!isAdmin) {
            return 'user'
        }
        //Admin box checked when editing AF user
        else if (process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID === customerId) {
            return 'af_admin'
        }
        //Admin box checked
        return 'admin';
    }

    const handleSubmit = async () => {
        //This variable controls how long we wait for the update/create info to be reflected in Auth0
        const refreshAwaitTime = 3000;
        setLoading(true);
        const role = determineAdminRole(user.isAdmin);

        //Edit user
        if (editUser) {
            await updateAuth0User(user, editUser.user_id, role)
            .then(async response => {
                //Success
                if (response.status === 200) {
                    setTimeout(async () => {
                        await getUsers().then(data => {
                            setUsers(data);
                        })
                    }, refreshAwaitTime);                    
                }
                else {
                    console.log("Could not update the user");
                }
            })
        }
        //Create user
        else {
            await createAuth0User(user, customerId, role)
            .then(async response => {
                //Success
                if (response.status === 201) {
                    setTimeout(async () => {
                        await getUsers().then(data => {
                            setUsers(data);
                        })
                    }, refreshAwaitTime);
                }
                else {
                    console.log("Could not create the user");
                }
            })
        }
        setTimeout(async () => {
            setLoading(false);
            setShowUserModal(false);
            setEditUser(null);  
        }, refreshAwaitTime);        
    }

    const handleCancel = () => {
        setShowUserModal(false);
        setEditUser(null);
    }

    const handlePasswordReset = async (email) => {
        if (!email) return;
        await auth0PasswordReset(email)
        .then(resp => {
            if (resp.status === 200) {
                setResetStatus("success");
            }
            else {
                setResetStatus('fail');
            }
        })
    }

    return (
        <div className="CreateUserModal">
            {
                loading
                ? <div className="Loading">
                    <Circles
                        height="80"
                        width="80"
                        color="#2386EE"
                        ariaLabel="circles-loading"
                        visible={true}
                    />
                </div>
                : <>
                    <div className="modal-header">
                        <h2>{editUser ? "Edit user" : "Create a user"}</h2>				
                    </div>
                    <div className="users-options">
                        <div className="input-container">
                            <p className="pure-u-1-4">First name:</p>
                            <input className="input" onChange={(e) => setUser({...user, firstName: e.target.value})} value={user?.firstName} />
                        </div>
                        <div className="input-container">
                            <p className="pure-u-1-4">Last name:</p>
                            <input className="input" onChange={(e) => setUser({...user, lastName: e.target.value})} value={user?.lastName} />
                        </div>
                        <div className="input-container">
                            <p className="pure-u-1-4">Email address:</p>
                            <input className="input" onChange={(e) => setUser({...user, email: e.target.value})} value={user?.email} />
                        </div>
                        <div className="input-container">
                            <p className="pure-u-1-4">Customer admin:</p>
                            <input className="checkbox-input" type="checkbox" onChange={(e) => setUser({...user, isAdmin: e.target.checked})} checked={user?.isAdmin} />
                        </div>
                        <div className="reset-container">
                            <p className="reset" onClick={() => handlePasswordReset(editUser?.email)}>Reset Password</p>
                            {
                                resetStatus === 'success'
                                ? <p className="message" style={{color: 'green'}}>Password reset email sent</p>
                                : resetStatus === 'fail'
                                ? <p className="message" style={{color: 'red'}}>Password reset email failed</p>                            
                                : <p style={{"visibility": "hidden"}}></p>
                            }
                        </div>

                    </div>
                    <div className="bottom-container">                        
                        <div className="buttons-container">
                            <button className="cancel-button" onClick={handleCancel}>Cancel</button>
                            <button className="modal-button" onClick={handleSubmit}>{editUser ? 'Update': 'Create'}</button>
                        </div>                        
                    </div>
                </>
            }			
        </div>
    )
}

export default UsersPage;
