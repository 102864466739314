const createAuth0User = async (newUser, customerId, role) => {

    return await fetch(`${process.env.REACT_APP_BIZ_BASE_URL}/api/customers/createAuth0User`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            connection: 'Username-Password-Authentication',
            customer_id: customerId,
            email: newUser.email,
            family_name: newUser.lastName,
            given_name: newUser.firstName,
            is_active: true,
            role: role
        })
    })
    .then(resp => resp)
    .catch((error) => error);
}

const getAuth0Users = async (customerId) => {

    return await fetch(`${process.env.REACT_APP_BIZ_BASE_URL}/api/customers/getAuth0Users/${customerId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(response => response.json())
    .then(data => data)
    .catch((error) => error);
}


const updateAuth0User = async (user, user_id, role) => {
    return await fetch(`${process.env.REACT_APP_BIZ_BASE_URL}/api/customers/updateAuth0User`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            connection: 'Username-Password-Authentication',
            email: user.email,
            family_name: user.lastName,
            given_name: user.firstName,
            id: user_id,
            is_active: true,
            role: role
        })
    })
    .then(resp => resp)
    .catch((error) => error);
}

const auth0PasswordReset = async (email) => {
    return await fetch(`${process.env.REACT_APP_BIZ_BASE_URL}/api/customers/auth0PasswordReset`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            connection: 'Username-Password-Authentication',
            email
        })
    })
    .then(resp => resp)
    .catch((error) => error);
}

export {
    createAuth0User,
    getAuth0Users,
    updateAuth0User,
    auth0PasswordReset
}
